import styled, { ThemeSpacing, css } from 'styled-components';

interface ContainerProps {
  spacing?: ThemeSpacing;
  minWidth: string;
  borderTop?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing['2xs']};

  > * {
    min-width: ${({ minWidth }) => minWidth};
    flex: 1;
  }

  & + & {
    margin-top: ${({ theme, spacing }) => spacing || theme.spacing['2xs']};
  }

  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid #ddd;
      padding-top: 20px;
    `}
`;
